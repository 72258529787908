import React from "react"
import { Link } from "gatsby"

import { ChevronRightIcon } from "@heroicons/react/solid"

import ContactSection from "../Home/ContactSection"

const NewsPage = ({ data }) => {
  const { img, title, paragraphs, date } = data
  return (
    <>
      <section
        id="news"
        aria-labelledby="news-data-heading"
        className="mx-auto max-w-xl py-6 px-0 sm:py-24 md:px-12 lg:max-w-4xl"
      >
        <div className="mt-6 grid grid-cols-1 space-y-2 sm:grid-cols-3 sm:gap-x-6 sm:space-y-0">
          <nav className="hidden px-2 sm:flex" aria-label="Breadcrumb">
            <ol className="flex min-w-max items-center space-x-4">
              <li>
                <div className="flex">
                  <Link
                    to="/"
                    className="text-base font-medium text-gray-500 hover:text-gray-700"
                  >
                    Главная
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <Link
                    to="/news"
                    className="ml-4 text-base font-medium text-gray-500 hover:text-gray-700"
                  >
                    Новости
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </div>

        <div id="news-data" className="mx-auto">
          <div className="px-4 sm:px-2">
            <h2
              id="news-data-heading"
              className="pt-0 text-left text-base font-semibold sm:pt-2 md:text-3xl"
            >
              {title}
            </h2>

            <img
              src={img}
              alt={`Фото статьи о ${title}`}
              className="w-full py-4"
            />
            {paragraphs.map(paragraph => (
              <p className="py-3 text-left text-sm font-normal text-gray-900 sm:py-4 sm:text-base">
                {paragraph}
              </p>
            ))}
            <p className="pt-4 text-sm text-gray-900 sm:text-base">
              Дата публикации: {date}
            </p>
          </div>
        </div>
      </section>
      <div className="border border-t-gray-300" />
      <ContactSection />
    </>
  )
}

export default NewsPage
